
import { Link,useNavigate } from "react-router-dom";
import { useState } from "react";
import swal from 'sweetalert';
import { doc, setDoc,getFirestore, addDoc,collection } from "firebase/firestore"; 
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import {ProgressBar} from "react-bootstrap"
import CheckUser from "./check.admin";
const AddEventsnap = () =>{
    const [progress, setProgress] = useState(0);
    const [pic,setPic]=useState("");


      const db=getFirestore();
      const storage = getStorage();
      const [temp,setTemp]=useState("")
    
      const AddSnapshot= (e)=>{
        uploadFiles(pic);
        e.preventDefault();
      }
    
      const uploadFiles = (pic) => {
        //
        if (!pic) return;
        const sotrageRef = ref(storage, `Snapshots/${pic.name}`);
        const uploadTask = uploadBytesResumable(sotrageRef, pic);
    
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const prog = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(prog);
          },
          (error) => console.table(error),
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log("File available at", downloadURL);
              console.table("downloadURL",typeof(downloadURL))
  
              const ref= collection(db, "Event-snaps")
              addDoc(ref,{
                  Dp:downloadURL,
              })
              .then(()=>{
                  swal({
                      title: "Event Snapshot Added",
                      icon: "success",
                      button: "Okay",
                    });
                    setPic("")
                    setProgress(0)
              })
              .catch((err)=>{
                  swal({
                      title: "Failed",
                      icon: "warning",
                      button: "Okay",
                    });
              })
            });
          }
        );
      };
    return(
        <>
        <div className="container text-center">
            <p className="h4 pl-4">Add Event Snapshots</p>
            <CheckUser/>
            <form onSubmit={AddSnapshot} className="text-center">
                    <div className="mb-3">
                            <label htmlFor="B_file" className="form-label">Choose snap</label>
                            <input className="form-control form-control1" type="file" 
                            accept=".png, .jpg, .jpeg"  onChange={(e)=>{setPic(e.target.files[0])}}/><br/>
                            <p>Uploading done {progress}%</p><ProgressBar animated now={progress} />
                         </div>
                        <button type="submit" className="cbtn1 btn shadow rounded">Upload</button>
             </form>
        </div>
        </>
    )

}
export default AddEventsnap;