import React, { Component, Suspense } from 'react';
import './App.css';
// import Footer from './Components/footer.component';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './Home';
import Team from './Components/team.component';
import Admit from './Admin/admit.admin';
import CreatAccount from './Admin/create.admin';
import { getMessaging, getToken } from "firebase/messaging";
import Events from './Components/pages/events.page';
import Dashboard from "./Admin/dashboard.admin";
import Images from './Admin/images.admin';
import AddProfie from './Admin/profile.admin';
import AddEventsnap from './Admin/addevent.admin';
import AddPopUp from './Admin/addpop.admin';
import ReactLoading from 'react-loading';
import LoadingComponent from "./Components/loadingComponent.component";
import PageNotFound from './Components/404.page';

const Navbar =  React.lazy(() => import("./Components/nav.component"));
const Footer =  React.lazy(() => import("./Components/footer.component"));
function App() {



  return (
    <div className="App">
        <Router>
        <Suspense fallback={<LoadingComponent/>}>

        <Navbar/>

        <Routes>
          <Route path="/" exact element={<Home/>}/>
          <Route path="/team" exact element={<Team/>}/>
          <Route path="/admin" exact element={<Admit/>}/>
          <Route path="/create" exact element={<CreatAccount/>}/>
          <Route path="/team" exact element={<Team/>}/>
          <Route path="/events" exact element={<Events/>}/>
          <Route path="/dashboard" exact element={<Dashboard/>} />
          <Route path="/dashboard/event-images" exact element={<Images/>} />
          <Route path="/dashboard/addprofile" exact element={<AddProfie/>} />
          <Route path="/dashboard/add-snapshot" exact element={<AddEventsnap/>} />
          <Route path="/dashboard/add-popup" exact element={<AddPopUp/>} />
          <Route path="*"  element={<PageNotFound/>} />
        </Routes>

        <Footer/>
</Suspense>
        </Router>

    </div>
  );
}

export default App;
