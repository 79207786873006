import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CreatAccount= ()=>{
    const history = useNavigate();
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [psw, setPsw] = useState('');

    async function registerUser(event){
        event.preventDefault()
       const response= await fetch("http://localhost:5001/api/create",
        {
            method:'POST',
            headers:{
                'Content-Type': 'application/json'    
            },
            body: JSON.stringify({
                name, 
                username,
                psw,
            }),
            
        })
        const data= await response.json();
        // console.log(data)

        if(data.status==='ok'){
            history.push('/admin')
        }
        
    }

    return(
        <div className="login container text-center">
        <form className="center loginform" onSubmit={registerUser}>
            <p className="display-6">Create Admin</p>
           
            <div className="form-floating mb-3">
                <input type="name" className="form-control" id="floatingInput" placeholder="abc"
                value={name}
                onChange={(e)=> setName(e.target.value)}
                />
                <label htmlFor="floatingInput">Name</label>
            </div>
            <div className="form-floating mb-3">
                <input type="text" className="form-control" id="floatingInput" placeholder="abc"
                value={username}
                onChange={(e)=> setUsername(e.target.value)} />
                <label htmlFor="floatingInput">Username</label>
            </div>
            <div className="form-floating">
                <input type="password" className="form-control" id="floatingPassword" placeholder="Password"
                value={psw}
                onChange={(e)=> setPsw(e.target.value)}/>
                <label htmlFor="floatingPassword">Password</label>
            </div>
            <button type="submit" className="btn admitbtn" >Create</button>
            {/* <a href="/dashboard" className="btn cbtn" >Create</a> */}
        </form>
        </div>
    )
}
export default CreatAccount;