
import { collection, getFirestore,getDocs,doc } from "firebase/firestore"; 
import { useState, useEffect } from "react";

import { LazyLoadImage } from 'react-lazy-load-image-component';



const Team =()=>{

    const [profiles, setProfiles] = useState([]);
   
    const db=getFirestore();

    const ref1= collection(db, "team-card");

    const [search1, setSearch1] = useState('');

    useEffect(() => {

        const getData =async ()=>{
            const data= await getDocs(ref1)
            console.table(data)
            setProfiles(data.docs.map( (doc)=>({
                ...doc.data(),id:doc.id,
            }) ))

            
            // .then((snapshot) =>{
            //     snapshot.docs.forEach((doc)=>{
            //         profiles.push({ ...doc.data(),
            //             id:doc.id,})
            //     })
                // console.table(profiles); 
                // setProfiles(profiles)
          
            // })
            // .catch( err =>{
            //     // console.log(err.message);
            // })
        }

        getData();
    },[]);
    const { search } = window.location;
    const query = new URLSearchParams(search).get('s');
   
    // console.table(profiles)

    const SearchBar = (e) =>{
        e.preventDefault();
        
    }
    
    return(
        <>
        <div className="team-section">
        <div className="container">
            
        <div className="row d-flex justify-content-center row-cols-2">
                <p className="h4">Our Team</p>
            <form onSubmit={SearchBar} className="d-flex csearch"> 
                <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"  value={search1}
                onChange={(e)=> setSearch1(e.target.value)} />
                <button className="btn btn-secondary" type="submit">Search</button>
            </form>
        </div>
       
            <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
                {/* {profiles.map((user, index) => (
                <div className="col">
                <div data-aos="fade-up" className="card ccard text-center ">
                <img src={user.Dp} className="profile-img rounded-circle" />
                <div className="card-body team-card">
                    <h5 className="card-title" >{user.Name}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">{user.Post}</h6>
                    <a href={user.Email} className="card-link fa fa-envelope"></a>
                    <a href={user.Linkedin} className="card-link fa fa-linkedin"></a>
                </div>
                </div>
                </div>
        ))} */}

        {profiles.map((user)=>{
            return(
                <div>
                    {" "}
                    <div className="col">
                    <div  className="card ccard text-center ">
                    <LazyLoadImage
                    effect="blur"
                    src={user.Dp} className="profile-img rounded-circle" />
                        {/* <img  />     */}
                        <div className="card-body team-card">
                            <h5 className="card-title" >{user.Name}</h5>
                            <h6 className="card-subtitle mb-2 text-muted">{user.Post}</h6>
                            <a href={user.Email} className="card-link fa fa-envelope"></a>
                            <a href={user.Linkedin} className="card-link fa fa-linkedin"></a>
                        </div>
                    </div>
                    </div>

                </div>
            )
        })}
            </div>
            </div>
        </div>

        </>
    )

}

export default Team;