import {Component} from "react";
import {Container, Row,Col}  from "react-bootstrap";
import Magazine from "./magazine.page";
import { collection, getFirestore,getDocs,doc } from "firebase/firestore"; 
import { useState,useEffect } from "react";
import { ImageViewer } from "react-image-viewer-dv";

const Events = ()=> {
    
    const db=getFirestore();
    const [snaps, setSnaps] = useState([]);

    const ref1= collection(db, "Event-snaps");

    useEffect(() => {

        const getData =async ()=>{
            const data= await getDocs(ref1)
            console.table(data)
            setSnaps(data.docs.map( (doc)=>({
                ...doc.data(),id:doc.id,
            }) ))
        }
        getData();
    },[]);

        return (<>
            <div className="container">
            <p className="h1">Events Snapshots</p>
                <div className="row gy-5">
                {snaps.map((snap)=>{
                    return(
                        <div className="col d-flex justify-content-center">
                            {" "}
                                <ImageViewer>
                                {/* <div class="centered">{snap.Text}</div> */}
                                <img src={snap.Dp}  className="img-fluid event-img" />
                                </ImageViewer>
                         </div>
                       
                    )
                })}
                </div>
            </div>
        </>);
    
}
 
export default Events;