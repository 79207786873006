import React, {Suspense} from "react";
import LoadingComponent from "./Components/loadingComponent.component";
import {app, db} from "./Firebase/auth"
import {  getToken,getMessaging } from "firebase/messaging";

const About =  React.lazy(() => import("./Components/about.component"));
const Event =  React.lazy(() => import("./Components/event.component"));
const Header =  React.lazy(() => import("./Components/header.component"));
const Location =  React.lazy(() => import("./Components/location.component"));
const Quote =  React.lazy(() => import("./Components/quote.component"));
const FAQ =  React.lazy(() => import("./Components/FAQ.component"));
const Highlight =  React.lazy(() => import("./Components/Highlight.component"));
const Video =  React.lazy(() => import("./Components/video.component"));
const GetPop =  React.lazy(() => import("./Components/pages/getpop.page"));
const  Join =  React.lazy(() => import("./Admin/join.admin"));
const Speak =  React.lazy(() => import("./Components/speak.component"));




const Home = () =>{

    const messaging = getMessaging();


    getToken(messaging, { vapidKey: 'BNAXb8yGCWwRcPJ9tJgQm_bKfwDy3eJ_Ep2e49Ex_EmyNpLPkSuFq5emYfWA_MMIPYcrmtUHgsT8t9zdAxg2QP8' }).then((currentToken) => {
      console.log(currentToken)

        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });

    return(
        <div>
      <Suspense fallback={<LoadingComponent/>}>

            <Header/>
            <Event/>
            <Highlight/>
            <Video/>
            <Quote/>
            <Speak/>
            <About/>
            <FAQ/>
            <Location/>
            <GetPop/>
            <Join/>
      </Suspense>

        </div>
    )

}
export default Home;