import { useEffect, useState } from "react";
import { app } from "../Firebase/auth"
import { Link,useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged,signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import GoogleButton from 'react-google-button'

import swal from 'sweetalert';

const Admit = ()=>{
    const [username, setUsername] = useState('');
    const [psw, setPsw] = useState('');

    let navigate = useNavigate();
    const auth = getAuth();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate(`/dashboard`);
            } else {
            }
        });
       
    },[]);
    

    const handleAction = (e) => {
        e.preventDefault();
        const authentication = getAuth();
        let username1 = username + "@gmail.com";
        signInWithEmailAndPassword(authentication, username1, psw)
            .then((response) => {
                swal({
                    title: "Sign in Succcesfully",
                    text: "Welcome Back Admin",
                    icon: "success",
                    button: "Okay",
                  });
                  navigate(`/dashboard`);
                
          })
          .catch((error) => {
                    // const errorCode = error.code;
                    // const errorMessage = error.message;
                    swal({
                        title: "Failed to sign in",
                        text: "please check sign in credintial",
                        icon: "error",
                        button: "Okay",
                      });
        });
        setUsername("");
        setPsw("");
    }

    const GHandle = ()=>{
        const provider = new GoogleAuthProvider();

        const auth = getAuth();
        signInWithPopup(auth, provider)
          .then((result) => {
            swal({
                title: "Sign in Succcesfully",
                text: "Welcome Back Admin",
                icon: "success",
                button: "Okay",
              });
              navigate(`/dashboard`);
          }).catch((error) => {
            swal({
                title: "Failed to sign in",
                text: "please check sign in credintial",
                icon: "error",
                button: "Okay",
              });
          });
    }
    return(
        <>
            <div className="container text-center">
            <p className="text-center display-6"> Admin Panel</p>
            <form className="center loginform" onSubmit={handleAction}>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="floatingInput" placeholder="abc"
                    value={username}
                    onChange={(e)=> setUsername(e.target.value)} />
                    <label htmlFor="floatingInput">Username</label>
                </div>
                <div className="form-floating">
                    <input type="password" className="form-control" id="floatingPassword" placeholder="Password"
                    value={psw}
                    onChange={(e)=> setPsw(e.target.value)}/>
                    <label htmlFor="floatingPassword">Password</label>
                </div>
                <button className="btn admitbtn" >Login</button>
               
                </form>
                <div className="d-flex justify-content-center">
                {/* <GoogleButton
                    onClick={GHandle}
                    /> */}
                </div>
               
                </div>
        </>
    )
}
export default Admit;