import logo from "../Assets/logo.svg";

const LoadingComponent = () => {
    return(
        <>
        <div className="parrent-div mt-6 mb-4">
            <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            </div>
        <div className="parrent-div">
            <div className='ripple1'>
                    <img src={logo} className='cimg  '/>
            </div>
        </div>
        </>
    )
}
export default LoadingComponent;