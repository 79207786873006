// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAAZ1_8EKkCkS_cJ--_jT8nmXF09Mrii8k",
    authDomain: "association-fddd4.firebaseapp.com",
    projectId: "association-fddd4",
    storageBucket: "association-fddd4.appspot.com",
    messagingSenderId: "476138242962",
    appId: "1:476138242962:web:b5df5ceebe015398cef74b",
    measurementId: "G-0M13SMGY7C"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging();