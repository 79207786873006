import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { Link,useNavigate } from "react-router-dom";
import swal from 'sweetalert';

const CheckUser = ()=>{

    let navigate = useNavigate();

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            
        } else {
            swal({
                title: "Buddy,login first",
                text: "Please login",
                icon: "warning",
                button: "Okay",
              });
            navigate(`/`);
        }
      });


    const handleSignOut = ()=>{
        signOut(auth).then(() => {
            swal({
                title: "Sign Out Succcesfully",
                icon: "success",
                button: "Okay",
              });
              navigate(`/`);
        }).catch((error) => {
  
        });
    }
      
        return (<>
             <div className="container text-center">
                <div className="col-sm">
                    <i className="fa fa-home h3" aria-hidden="true"></i>
                    <p className="h3"></p>
                    <button onClick={handleSignOut} className="btn admitbtn">Sign Out</button>
                    <Link to="/dashboard" className="admitbtn btn">Back</Link>
                </div>
            </div>
            
        </>);
}

export default CheckUser;