import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { Link,useNavigate } from "react-router-dom";
import { useState } from "react";
import swal from 'sweetalert';
import { doc, setDoc,getFirestore, addDoc,collection } from "firebase/firestore"; 
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import {ProgressBar} from "react-bootstrap"
import CheckUser from "./check.admin";
const AddProfie = ()=>{
    let navigate = useNavigate();
    const [progress, setProgress] = useState(0);

    const [name,setname]=useState("");
    const [email,setemail]=useState("");
    const [post,setPost]=useState("");
    const [College,setCollege]=useState("");
    const [Url,setUrl]=useState("");
    // const [img,setImage]=useState("");

    const [pic,setPic]=useState("");
    
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            
        } else {
            swal({
                title: "Buddy,login first",
                text: "Please login",
                icon: "warning",
                button: "Okay",
              });
            navigate(`/`);
        }
      });
      const db=getFirestore();
      const storage = getStorage();
      const [temp,setTemp]=useState("")
    
      const AddTeamProfile= (e)=>{
        uploadFiles(pic);
        e.preventDefault();
      }


  
    const uploadFiles = (pic) => {
      //
      if (!pic) return;
      const sotrageRef = ref(storage, `files/${pic.name}`);
      const uploadTask = uploadBytesResumable(sotrageRef, pic);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(prog);
        },
        (error) => console.table(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            console.table("downloadURL",typeof(downloadURL))

            const ref= collection(db, "team-card")
            addDoc(ref,{
                Name: name,
                Post: post,
                Email: email,
                Linkedin: Url.toString(),
                Dp:downloadURL,
            })
            .then(()=>{
                swal({
                    title: "Team Profile Added",
                    icon: "success",
                    button: "Okay",
                  });
                  setname("")
                  setPost("")
                  setUrl("")
                  setemail("")
                  setPic("")
                  setProgress(0)
            })
            .catch((err)=>{
                swal({
                    title: "Failed",
                    icon: "warning",
                    button: "Okay",
                  });
            })
          });
        }
      );
    };
    return(
        <>
        <div className="container d-flex justify-content-center">
          <form onSubmit={AddTeamProfile} className="text-center">
                <p className="h4">Add Profile card</p>
                <CheckUser/>
                        <div className="mb-3">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control form-control1" id="floatingInput" placeholder="Shivam" value={name} onChange={(e)=> setname(e.target.value)}  required/>
                            <label htmlFor="floatingInput">Name</label>
                            </div>
                        </div>
                        <div className="mb-3">
                        <div className="form-floating mb-3">
                            <input type="email" className="form-control form-control1" id="floatingInput" placeholder="name@example.com" value={email} onChange={(e)=> setemail(e.target.value)} required />
                            <label htmlFor="floatingInput">Email address</label>
                            </div>
                        </div>
                        <div className="mb-3">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control form-control1" id="floatingInput"  placeholder="GHRIET PUNE" value={post} onChange={(e)=> setPost(e.target.value)} required />
                            <label htmlFor="floatingInput">Post</label>
                            </div>
                        </div>
                        <div className="mb-3">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control form-control1" id="floatingInput"  placeholder="https://github.com/project01" value={Url} onChange={(e)=> setUrl(e.target.value)} required />
                            <label htmlFor="floatingInput">LinkedIn Profile Url</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="B_file" className="form-label">Choose Profile Pic</label>
                            <input className="form-control form-control1" type="file" 
                            accept=".png, .jpg, .jpeg"  onChange={(e)=>{setPic(e.target.files[0])}}/><br/>
                            <p>Uploading done {progress}%</p><ProgressBar animated now={progress} />
                         </div>
                        <button type="submit" className="cbtn1 btn shadow rounded">Add</button>
                    </form>
                </div>      
        </>
    )
}
export default AddProfie;