import {Component} from "react";
import page from "../Assets/404.gif"
class PageNotFound extends Component {
    state = {  } 
    render() { 
        return (<>
            <div className="container NotFound text-center">
                <div className="row row-cols-auto d-flex justify-content-center">
                    <img src={page} className="img-fluid"/>
                </div>
            </div>
        </>);
    }
}
 
export default PageNotFound;