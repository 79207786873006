import CheckUser from "./check.admin"
import { useState,useEffect } from "react";
import { doc, setDoc,getFirestore, addDoc,collection ,updateDoc} from "firebase/firestore"; 
import swal from 'sweetalert';

const AddPopUp = () =>{
    const [heading, setheading] = useState('');
    const [message, setMessage] = useState('');
    const [property,setProperty] = useState('');
    const db=getFirestore();


    // console.table(property)

    const AddBox = (e) =>{
        e.preventDefault();
            const setData =async ()=>{

                const washingtonRef = doc(db, "PopUp", "5Ed2h4xjXAHvY3a0cBIW");
                updateDoc(washingtonRef, {
                    Property: property,
                    Heading: heading,
                    Message: message,
                })
                .then(()=>{
                    swal({
                        title: "PopUp Updated",
                        icon: "success",
                        button: "Okay",
                      });
                      setheading("");
                      setMessage("");
                      setProperty("");

                })
                .catch((err)=>{
                    swal({
                        title: "Failed",
                        icon: "warning",
                        button: "Okay",
                      });
                })
            }
            setData();
    }
    return(<>
        <div className="container text-center">
            <p className="h4 pl-4">Update PopUp</p>
            <CheckUser/>
            <form className="center loginform" onSubmit={AddBox}>
            <div className="form-floating mb-3">
                <select className="form-select form-control" id="floatingSelect" aria-label="Floating label select example"   value={property} 
                onChange={(e)=> setProperty(e.target.value)}>
                    <option selected>Open this select property</option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                </select>
                <label htmlFor="floatingSelect">Display property</label>
            </div>
            <div className="form-floating mb-3">
                <input type="name" className="form-control" id="floatingInput" placeholder="abc"
                value={heading}
                onChange={(e)=> setheading(e.target.value)}
                />
                <label htmlFor="floatingInput">Heading</label>
            </div>
            <div className="form-floating mb-3">
                <textarea type="text" className="form-control" id="floatingInput"  style={{height:100}} placeholder="abc" 
                value={message}
                onChange={(e)=> setMessage(e.target.value)} />
                <label htmlFor="floatingInput">Mesaage</label>
            </div>
            <button type="submit" className="btn admitbtn" >Update</button>
        </form>
        </div>
    </>)

}
export default AddPopUp